<template>
  <v-list tile nav class="pr-0">
    <template v-for="item in filteredRoutes">
      <v-list-group v-if="!!item.items && item.items.length"
                    active-class="activeClass"
                    v-model="item.active"
                    :disabled="item.disabled"
                    :prepend-icon="item.icon"
                    :key="item.title">
        <template #activator>
          <KListTitle v-bind="item"/>
        </template>
        <KListTitle v-bind="subItem" v-for="subItem in item.items" :key="subItem.title"/>
      </v-list-group>

      <KListTitle v-else :key="item.title" v-bind="item"/>
    </template>
    <div class="text--disabled" v-if="hasAdminRoutes">{{ $t('global.adminMenuTitle') }}</div>
    <VDivider v-if="hasAdminRoutes"/>
    <template v-for="item in filteredAdminRoutes">
      <v-list-group v-if="!!item.items && item.items.length"
                    active-class="activeClass"
                    v-model="item.active"
                    :disabled="item.disabled"
                    :prepend-icon="item.icon"
                    :key="item.title">
        <template #activator>
          <KListTitle v-bind="item"/>
        </template>
        <KListTitle v-bind="subItem" v-for="subItem in item.items" :key="subItem.title"/>
      </v-list-group>

      <KListTitle v-else :key="item.title" v-bind="item"/>
    </template>
  </v-list>
</template>

<script>
import permissions from '@/application/enums/permissions.js';
import KListTitle from '@/components/menu/KListTitle.vue';
import {mapGetters} from 'vuex';

export default {
  name: 'TheMainMenu',
  components: {KListTitle},
  computed: {
    ...mapGetters({
      isAdmin: 'authorisation/isAdmin',
      isApprover: 'authorisation/isApprover',
      isOrderer: 'authorisation/isOrderer',
      modules: 'client/getModules'
    }),
    items() {
      return [
        {
          route: {name: 'home'},
          title: this.$t('home.menuTitle'),
          icon: '$home',
          sort: 0,
        },
        {
          route: {name: 'locationDetails'},
          title: this.$t('locationDetails.menuTitle'),
          icon: '$locationDetails',
          sort: 10,
          permissions: [permissions.ORDERER],
          module: 'storeManagement'
        },
        {
          route: {name: 'my-orders.index'},
          title: this.$t('orders.myOrders.menuTitle'),
          icon: '$myOrders',
          sort: 20,
          permissions: [permissions.ORDERER],
        },
        {
          route: {name: 'my-tickets.index'},
          title: this.$t('ticket.myTickets.menuTitle'),
          icon: '$tickets',
          sort: 20,
          permissions: [permissions.ORDERER],
          module: 'customerSupport'
        },
        {
          route: {name: 'catalog.category'},
          title: this.$t('catalog.menuTitle'),
          icon: '$catalog',
          sort: 50,
          permissions: [permissions.ORDERER],
        },
        {
          route: {name: 'configurator.index'},
          title: this.$t('configurator.menuTitle'),
          icon: '$configurator',
          sort: 60,
          permissions: [permissions.ORDERER],
          module: '3DConfigurator'
        },
        {
          route: {name: 'approvals.index'},
          title: this.$t('approvals.menuTitle'),
          icon: '$approvals',
          sort: 10,
          module: 'approvalsModule',
          permissions: [permissions.APPROVER],
        },
        {
          route: {name: 'user.index'},
          title: this.$t('user.menuTitle'),
          icon: '$user',
          sort: 30,
          belowAdministratorOptions: true,
          permissions: [permissions.ADMIN],
        },
        {
          route: {name: 'store.index'},
          title: this.$t('store.menuTitle'),
          icon: '$store',
          sort: 40,
          belowAdministratorOptions: true,
          permissions: [permissions.ADMIN],
          module: 'storeManagement'
        },
        {
          route: {name: 'orders.index'},
          title: this.$t('orders.menuTitle'),
          icon: '$orders',
          sort: 50,
          belowAdministratorOptions: true,
          permissions: [permissions.ADMIN],
        },
        {
          route: {name: 'roles.index'},
          title: this.$t('role.menuTitle'),
          icon: '$roles',
          sort: 50,
          belowAdministratorOptions: true,
          permissions: [permissions.ADMIN],
        },
        {
          route: {name: 'regions.index'},
          title: this.$t('regions.menuTitle'),
          icon: '$regions',
          sort: 50,
          belowAdministratorOptions: true,
          permissions: [permissions.ADMIN],
        },
        {
          route: {name: 'tickets.index'},
          title: this.$t('ticket.tickets.menuTitle'),
          icon: '$tickets',
          sort: 50,
          belowAdministratorOptions: true,
          permissions: [permissions.ADMIN],
          module: 'customerSupport'
        },
        {
          route: {name: 'notifications.index'},
          title: this.$t('notifications.menuTitle'),
          icon: '$notifications',
          sort: 50,
          belowAdministratorOptions: true,
          permissions: [permissions.ADMIN],
        },
      ];
    },
    filteredRoutes() {
      return this.permissionChecker(this.sortRoutes(this.items).filter(item => !item.belowAdministratorOptions));
    },
    filteredAdminRoutes() {
      return this.permissionChecker(this.sortRoutes(this.items).filter(item => item.belowAdministratorOptions));
    },
    hasAdminRoutes() {
      return this.filteredAdminRoutes.length > 0;
    },
  },
  methods: {
    sortRoutes(routes) {
      return routes.sort((firstItem, secondItem) => firstItem.sort - secondItem.sort);
    },
    permissionChecker(routes) {
      return routes.filter(route => {
        if (!route.permissions) return true;
        if (route.module && !this.modules[route.module]) return false;

        const convertPermissions = route.permissions.map((permission) => {
          switch (permission) {
            case permissions.ORDERER:
              return this.isOrderer;
            case permissions.APPROVER:
              return this.isApprover;
            case permissions.ADMIN:
              return this.isAdmin;
            default:
              return false;
          }
        });
        return convertPermissions.some((val) => val);
      });
    }
  }
};
</script>

<style scoped>
.activeClass {
  color: #ffffff;
  background-color: #000000;
  border-radius: 0;
}
</style>

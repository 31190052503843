<template>
  <img style='height: 35px; width:auto' :src="getLogoUrl" alt="Logo">
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'KawasakiLogo',
  computed: {
    ...mapGetters('client', ['getLogoUrl'])
  }
};
</script>

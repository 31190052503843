<template>
  <div class="fill-height">
    <v-system-bar app color="secondary" dark fixed height="62" class="pa-0">
      <div style="height: 45px; width: 100%" class="d-flex pr-5 pl-3">
        <v-btn icon v-if="$vuetify.breakpoint.mobile" @click.stop="isMenuOpen = !isMenuOpen">
          <v-icon>$hamburgerMenu</v-icon>
        </v-btn>
        <KawasakiLogo style="height: 36px" :background-fill="$vuetify.theme.themes.light.secondary" class="ml-2"/>
        <VSpacer/>
        <div class="d-flex mr-6" v-if="isOrderer && getModules['storeManagement']" style="align-items: flex-start">
          <KSelect v-model="currentStoreId"
                   :items="storeList"
                   item-text="name"
                   item-value="id"
                   outlined
                   dense
                   dark
                   :menuProps="{tile: true, dark: true, offsetY: true,'content-class':'store-dropdown'}"
                   hide-details
                   return-object
                   no-label>
          </KSelect>
        </div>
        <AppBarMenu/>
        <v-badge offset-x="9" offset-y="11" color="warning" dot :value="hasItemsInCart" v-if="isOrderer">
          <v-btn icon :to="{name: 'shoppingCart'}">
            <v-icon>$shoppingCart</v-icon>
          </v-btn>
        </v-badge>
      </div>
      <div style="position: absolute; bottom: 0; width: 100%">
        <hr style="height: 3px" class="primary"/>
      </div>
    </v-system-bar>
    <v-main class="fill-height">
      <v-navigation-drawer v-model="isMenuOpen" app clipped width="316" color="pt-6">
        <TheMainMenu class="pl-4"/>
      </v-navigation-drawer>
      <router-view/>
    </v-main>
  </div>
</template>

<script>
import KSelect from '@/components/crud/fields/KSelect.vue';
import AppBarMenu from '@/components/menu/AppBarMenu.vue';
import TheMainMenu from '@/components/menu/TheMainMenu.vue';
import KawasakiLogo from '@/images/KawasakiLogo.vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'Default',
  components: {
    KSelect,
    KawasakiLogo,
    AppBarMenu,
    TheMainMenu,
  },
  data() {
    return {
      isMenuOpen: true,
      shopsListLoading: false
    };
  },
  watch: {
    getStoreList: {
      handler(val) {
        if (!this.isOrderer) return;

        const firstId = val[0]?.id;
        if (firstId && !this.currentStoreId) this.setStoreToFirstStore(val);
        if (firstId && !val.map(store => store.id).includes(this.currentStoreId)) this.setStoreToFirstStore(val);

        const storeId = this.currentStoreId || firstId;
        if (storeId) this.fetchAmountInCart(storeId);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('authorisation', ['getStoreList', 'getFavouriteStores', 'isLoggedIn', 'getCurrentClientId', 'isOrderer']),
    ...mapGetters('settings', ['getCurrentStoreId']),
    ...mapGetters('shoppingCart', ['hasItemsInCart']),
    ...mapGetters('client', ['getModules']),
    ...mapState('shoppingCart', ['inCart']),
    currentStoreId: {
      get() {
        return this.getCurrentStoreId;
      },
      set({
        id,
        floorplannerProjectId,
      }) {
        this.updateCurrentStoreId(id);
        this.updateFloorplannerId(floorplannerProjectId);
      },
    },
    storeList() {
      const items = [];

      if(this.getFavouriteStores.length > 0){
        items.push({ header: this.$t('store.tabs.favourites') });

        this.getFavouriteStores.forEach(store => {
          items.push(store);
        });
      }

      if(this.getFavouriteStores.length > 0)
        items.push({ header: this.$t('store.tabs.others') });

      this.getStoreList.forEach(store => {
        if(!this.getFavouriteStores.includes(store))
          items.push(store);
      });

      return items;
    }
  },
  async created() {
    if (!this.isLoggedIn) this.$router.push({ name: 'login' });
  },
  methods: {
    ...mapActions('settings', ['updateCurrentStoreId', 'updateFloorplannerId']),
    ...mapActions('shoppingCart', ['fetchAmountInCart']),
    setStoreToFirstStore(storeList) {
      this.currentStoreId = { ...storeList[0] };
    }
  },
};
</script>

<style>
.v-menu__content.store-dropdown {
  z-index: 1000!important;
}
</style>
